<template>
  <div class="c-show-visit-on-map" @click="close">
    <n-loader :loading="$var('load')" />
    <div class="form" @click.stop>
      <div class="title">Просмотр местоположения посещения</div>

      <div id="map"></div>

      <div class="buttons">
        <n-button class="cancel" @click="close">Закрыть</n-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CSHowVisitOnMap',
  props: {
    lat: {
      type: String,
      required: true,
    },
    lon: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      map: null,
    }
  },
  watch: {
    lat() {
      this.changeByCoords({ latitude: this.lat, longitude: this.lon, })
    },
  },
  mounted() {
    ymaps.ready(this.initMap)
  },
  created() {

  },
  methods: {
    close() {
      this.$emit('update:show', false)
    },
    initMap() {
      const lat = this.lat
      const lon = this.lon
      this.map = new ymaps.Map('map', {
        center: [ lat, lon, ],
        zoom: 15,
      })
      this.changeByCoords([ lat, lon, ])
    },
    selectAddress(v, coords = null) {
      if (v.obj) {
        if (!coords) {
          this.address = v
          this.map.geoObjects.removeAll()
          this.map.geoObjects.add(v.obj)
          this.map.setBounds(v.obj.properties.get('boundedBy'), {
            checkZoomRange: true,
          })
        } else {
          this.map.geoObjects.removeAll()
          const placemark = new ymaps.Placemark(coords, {})
          this.map.geoObjects.add(placemark)
        }
      }
    },
    changeByCoords(coords) {
      ymaps.geocode(coords).then((res) => {
        const firstGeoObject = res.geoObjects.get(0)
        if (firstGeoObject) {
          this.map.geoObjects.removeAll()
          const addresses = []
          res.geoObjects.each((item) => {
            addresses.push({
              value: item.properties.get('uriMetaData.URI.uri'),
              title: item.properties.get('text'),
              coords: item.geometry.getCoordinates(),
              obj: item,
            })
          })
          this.address = addresses[0]
          this.selectAddress(addresses[0], coords)
          this.addresses = addresses
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-show-visit-on-map {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #00000080;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  :deep() {
    .n-select {
      .vs__actions {
        display: none;
      }
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .n-button {
      border: none;
      border-radius: 24px;
      background-color: var(--primary) !important;
      color: #FFFFFF;
    }
  }
  .buttons {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form {
    padding: 15px;
    z-index: 1001;
    min-width: 400px;
    min-height: 200px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 2px solid var(--primary);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: stretch;


    #map {
      margin: 20px;
      width: 500px;
      height: 300px;
      border-radius: 12px;
      overflow: hidden;
      border: 1px solid var(--primary);
    }

    .title {
      font-size: 16px;
      color: #000000;
      font-weight: 500;
      &.main {
        margin-bottom: 15px;
      }
    }
  }
}
</style>
