export default [
  { path: '', component: () => import('layouts/App'), children: [
    { path: '', component: () => import('layouts/auth/Index'), children: [
      { path: '', name: 'login', icon: 'genderless', component: () => import('pages/auth/Index'), },
    ], },
    { path: '', component: () => import('layouts/main/Index'), children: [
      { path: '/index', name: 'index', icon: 'genderless', component: () => import('pages/index/Index'), },
      { path: '/users', name: 'users', icon: 'genderless', component: () => import('pages/users/Index'), },
      { path: '/departments', name: 'departments', icon: 'genderless', component: () => import('pages/departments/Index'), },
      { path: '/department/:id', name: 'department', icon: 'genderless', component: () => import('pages/department/Index'), },
      { path: '/branch/:id', name: 'branch', icon: 'genderless', component: () => import('pages/branch/Index'), },
      { path: '/profile', name: 'profile', icon: 'genderless', component: () => import('pages/profile/Index'), },
      { path: '/user/:id', name: 'user', icon: 'genderless', component: () => import('pages/user/Index'), },
      { path: '/stats', name: 'stats', icon: 'genderless', component: () => import('pages/stats/Index'), },
      { path: '/timesheet', name: 'timesheet', icon: 'genderless', component: () => import('pages/timesheet/Index'), },
      { path: '/checklists', name: 'checklists', icon: 'genderless', component: () => import('pages/checklists/Index'), },
      { path: '/checklist/:id', name: 'checklist', icon: 'genderless', component: () => import('pages/checklist/Index'), },
      { path: '/checklist/:checklistId/checkpoint/:id', name: 'checkpoint', icon: 'genderless', component: () => import('pages/checkpoint/Index'), },
      { path: '/organizations', name: 'organizations', icon: 'genderless', component: () => import('pages/organizations/Index'), },
      { path: '/organizations/:id', name: 'organization', icon: 'genderless', component: () => import('pages/organization/Index'), },
      { path: '/exports', name: 'exports', icon: 'genderless', component: () => import('pages/exports/Index'), },
      { path: '/schedules', name: 'scheduleTemplates', icon: 'genderless', component: () => import('pages/scheduleTemplates/Index'), },
      { path: '/schedules/:id', name: 'scheduleTemplate', icon: 'genderless', component: () => import('pages/scheduleTemplate/Index'), },
      { path: '/positions', name: 'positions', icon: 'genderless', component: () => import('pages/positions/Index'), },
      { path: '/positions/:id', name: 'position', icon: 'genderless', component: () => import('pages/position/Index'), },
      { path: '/location-points', name: 'locationPoints', icon: 'genderless', component: () => import('pages/locationPoints/Index'), },
      { path: '/location-points/:id', name: 'locationPoint', icon: 'genderless', component: () => import('pages/locationPoint/Index'), },
      { path: '/applications', name: 'applications', icon: 'genderless', component: () => import('pages/applications/Index'), },
      { path: '/notifications', name: 'notifications', icon: 'genderless', component: () => import('pages/notifications/Index'), },
      { path: '/notifications-add', name: 'notificationsAdd', icon: 'genderless', component: () => import('pages/notifications/Add'), },
    ], },
  ], },
]
