<template>
  <div>
    <div v-for="notify in notifies" :key="notify.id" class="n-notify" @click=" $app.ui.closeNotify(notify.id)">
      <div class="body-place">
        <div class="body" :class="notify.color">
          {{ notify.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NNotify',
  computed: {
    notifies() {
      return $app.store.state('ui.notifies')
    },
  },
}
</script>

<style lang="scss" scoped>
.n-notify {
  width: 100vw;
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;

  display: flex;
  justify-content: center;
  background-color: rgba(180, 180, 180, 0.34);


  .body {
    margin-top: 5vh;
    border-radius: 12px;
    background-color: #ffffff;
    padding: 20px;
    display: block;
    &.danger {
      border: 3px solid red;
    }
  }
}
</style>
